// @ts-ignore
import { create } from 'axios';
import { getTokenAfterLogin } from '../authentication/msal';
const { env } = require('../env/env');

/**@type {import('axios').AxiosInstance} */
const instance = create({
    baseURL: env.REACT_APP_API_URL,
    responseType: 'json',
});

instance.interceptors.request.use(
    async (config) => {
        if (config.headers) {
            config.headers.Authorization= sessionStorage.getItem('msal.4ac593bb-4c54-4c30-84cc-bc71c642cd43.idtoken');
            return config;
        }
        else
        {
            let token = await getTokenAfterLogin();
            if(token)
            {
                config.headers.Authorization = `Bearer ${token.accessToken}`;
            }
        }
    },
    (error) => {
        Promise.reject(error);
    }
);

export default instance;
